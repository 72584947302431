import React from "react"
import SnowSlides from "../components/SnowSlides"
import { Layout } from "../components/Layout"

const SnowSlidesPage = () => {
  return (
    <Layout>
      <SnowSlides />
    </Layout>
  )
}

export default SnowSlidesPage
