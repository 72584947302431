import React from "react"
import cuid from "cuid"
import { Row, Col } from "react-styled-flexboxgrid"

import Gallery from "react-photo-gallery"
import FlexContainer from "../FlexContainer"
import PricingDimensionCard from "../PricingDimensionCard"
import { Primary, Alternate } from "../Section"
import Button from "../Button"
import bk1 from "../../images/snow_slides/big-king-1.jpg"
import bk2 from "../../images/snow_slides/big-king-2.jpg"
import bk3 from "../../images/snow_slides/big-king-3.jpg"
import bk4 from "../../images/snow_slides/big-king-4.jpg"
import bk5 from "../../images/snow_slides/big-king-5.jpg"
import lk1 from "../../images/snow_slides/lil-king-1.jpg"
import lk2 from "../../images/snow_slides/lil-king-2.jpg"
import lk3 from "../../images/snow_slides/lil-king-3.jpg"
import lk4 from "../../images/snow_slides/lil-king-4.jpg"

const pricing = [
  {
    id: 0,
    title: "Big King",
    price: "5,000",
    description1: "For ages 5 to adult – 10 feet high. 50 foot run",
  },
  {
    id: 1,
    title: "Little King",
    price: "3,200",
    description1: "For ages 3 to 6 – 6 feet high 35 feet long",
  },
]
const galleryBigKing = [
  {
    id: cuid(),
    src: bk1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: bk2,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: bk3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: bk4,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: bk5,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
]
const galleryLilKing = [
  {
    id: cuid(),
    src: lk1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: lk2,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: lk3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
  {
    id: cuid(),
    src: lk4,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Play area of snow with people",
  },
]

const SnowSlides = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Slide Dimension and Pricing</h1>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <FlexContainer justifyContent="space-evenly">
              {pricing.map(price => {
                return (
                  <PricingDimensionCard
                    id={price.id}
                    title={price.title}
                    price={price.price}
                    description1={price.description1}
                    description2={price.description2}
                  />
                )
              })}
            </FlexContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Gallery photos={galleryBigKing} />
          </Col>
          <Col xs={12} md={6}>
            <Gallery photos={galleryLilKing} />
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default SnowSlides
